import userTypes from '@services/userService/userTypes'
import classname from 'classname'
import _ from 'lodash'
import moment from 'moment'
import AvatarImg from './AvatarImg'
import Sticker from './Sticker'

const ChatMessageItem = ({ chatItem, style, onLoad, ref }) => {
  if (!chatItem)
    return null

  const { userName, level, createdAt, message, userId, bot, userType } = chatItem
  const levelTxt = `LV ${level}`
  const timestamp = moment(createdAt).format()
  const lvClass = userType === userTypes.admin ? 'red' : 'purpil'

  const renderUserName = username =>
    _.trim(username) || 'Guest'

  return (
    <div className={classname("message d-flex flex-column w-100 new py-2")} style={style} ref={ref}>
      <div className={classname({ "d-flex align-items-start": !_.trim(chatItem.sticker), "d-flex align-items-center": _.trim(chatItem.sticker) })}>
        <span className={classname("author-info rounded p-1 d-flex justify-content-between align-items-center p-1", lvClass)}>
          <figure className="avatar rounded-circle">
            <AvatarImg userId={userId} width={30} height={30} />
          </figure>
          <span className="ms-1">{levelTxt}</span>
        </span>
        <span className="ms-1 d-flex justify-content-center align-items-start">
          <span className={classname("ps-1 d-inline-block ms-1")}>
            <span className="author text-nowrap me-1">{renderUserName(userName)}:</span>
            <span className={classname({ "is-admin": chatItem.userType === 'admin' })}>
              {_.trim(message)}
              {chatItem.sticker && <Sticker stickerId={chatItem.sticker} />}
            </span>
          </span>
        </span>
      </div>
    </div>
  )
}

export default ChatMessageItem