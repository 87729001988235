import '@scss/main.scss';
import Home from '@views/Home';
import { Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';

const Loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)


const App = () => {
  return (
    <HashRouter>
      <Suspense fallback={Loading}>
        <Routes>
          <Route path="/" name="Home" element={<Home />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
