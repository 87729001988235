const classname = require("classname")
import { useSelector } from 'react-redux'

const Sticker = ({ className, stickerId }) => {
  const globalSettings = useSelector(state => state.globalSettings)

  return (
    <img
      style={{ height: 50, width: 50 }}
      src={`${globalSettings.fileServerUrl}/api/storage/photo/${stickerId}`}
      className={classname(className, 'img-fluid')}
    />
  )
}

export default Sticker