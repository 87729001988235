import { actionTypes } from "@stores/actions/chatMesage";
import _ from "lodash";

const defaultState = {
  chatMessages: [],
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_CHAT_MESSAGES:
      return {
        ...state,
        chatMessages: action.chatMessages
      }
    case actionTypes.PUSH_CHAT_MESSAGE: {
      const { chatMessages } = state
      return {
        ...state,
        chatMessages: _.concat(chatMessages, [action.chatMessage])
      }
    }
    default:
      return state;
  }
}
