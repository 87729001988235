import { yupResolver } from "@hookform/resolvers/yup"
import authService from "@services/authService"
import userService from "@services/userService"
import { setCurrentUser, setGuestUserName } from "@stores/actions/chat"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useAsyncFn } from "react-use"
import Swal from "sweetalert2"
import * as yup from "yup"

const guestSchema = yup
  .object({
    username: yup.string().nullable().required()
  })
  .required()

const ChatUserLogin = () => {
  const { currentUser } = useSelector(state => state.chat)
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()
  const { register, handleSubmit, reset } = useForm({ resolver: yupResolver(guestSchema) })

  useEffect(() => {
    reset({})
  }, [currentUser])

  const [{ loading: loadingSubmit }, onSubmit] = useAsyncFn(async input => {
    const user = await userService.lookup(input.username)

    if (!user) {
      reset({ username: null })
      return dispatch(setGuestUserName(input.username))
    }

    setShowPassword(true)
  })

  const [{ loading: loginLoading }, login] = useAsyncFn(input => {
    if (!_.trim(input.password) || !_.trim(input.username))
      return Promise.resolve({})

    return authService.login({ userName: input.username, password: input.password })
      .then(({ accessToken, currentUser }) => {
        authService.saveAccessToken(accessToken)
        dispatch(setCurrentUser({
          name: currentUser.name,
          userName: currentUser.userName,
          userId: currentUser._id,
          level: currentUser.level,
          userType: currentUser.userType
        }))
      })
      .catch(result => {
        if (result.response?.data?.error) {
          const { response: { data: { error } } } = result

          return Swal.fire({
            icon: "error",
            title: error.message,
            text: error.detail
          })
        }
      })
  })

  const cancelPassword = () => {
    setShowPassword(false)
  }

  if (authService.hasEndUserUse(currentUser))
    return null

  return (
    <div className="user-login-wrapper d-flex flex-fill mb-2">
      <form className="user-login-form d-flex align-items-center w-100 position-relative" id="frm-login" onSubmit={!showPassword ? handleSubmit(onSubmit) : handleSubmit(login)}>
        <div className="d-flex justify-content-between align-items-center flex-fill">
          <div className="position-relative flex-fill">
            <input {...register('username')} type="text" className="form-control form-control-lg w-100 ps-5" placeholder="Tên hiển thị" />
            <span className="position-absolute avt"><i className="fa-solid fa-user-large"></i></span>
          </div>
          {showPassword && (
            <div className="position-relative flex-fill ms-2">
              <input {...register('password')} type="password" className="form-control form-control-lg flex-fill ps-5" placeholder="Mật khẩu" />
              <span className="position-absolute password"><i className="fa-solid fa-lock"></i></span>
              <span onClick={cancelPassword} className="position-absolute cancel-password" role="button"><i className="fa-solid fa-xmark"></i></span>
            </div>
          )}
        </div>
        <button type="submit" className="btn-login btn btn-primary ms-2 px-3" disabled={loginLoading || loadingSubmit}>
          <span> <i className="fa-solid fa-arrow-right-to-bracket"></i> </span>
        </button>
      </form>
    </div>
  )
}

export default ChatUserLogin