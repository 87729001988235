import { useSelector } from "react-redux"
import { AutoSizer, Collection } from "react-virtualized"

const GUTTER_SIZE = 3
const CELL_WIDTH = 50
const COLUMN = 5
const COLUMN_HEIGHT = 50

const StickerCollection = ({ value, onSelect }) => {
  const globalSettings = useSelector(state => state.globalSettings)
  const _columnYMap = []

  return (
    <AutoSizer >
      {({ width }) => (
        <div >
          <Collection
            cellCount={_.size(value)}
            cellSizeAndPositionGetter={({ index }) => {
              const columnCount = COLUMN
              const columnPosition = index % (columnCount || 1)

              // Poor man's Masonry layout; columns won't all line up equally with the bottom.
              const height = COLUMN_HEIGHT
              const width = CELL_WIDTH;
              const x = columnPosition * (GUTTER_SIZE + width)
              const y = _columnYMap[columnPosition] || 0

              _columnYMap[columnPosition] = y + height + GUTTER_SIZE;

              return {
                height,
                width,
                x,
                y,
              }
            }}
            cellRenderer={({ index, key, style }) => {
              return (
                <div
                  role="button"
                  key={key}
                  style={style}
                  onClick={() => onSelect?.(_.get(value, [index, '_id']))}
                  className="sticker-item"
                >
                  <img className="img-fluid rounded" src={`${globalSettings.fileServerUrl}/api/storage/photo/${_.get(value, [index, '_id'])}`} />
                </div>
              )
            }}
            height={410}
            horizontalOverscanSize={0}
            verticalOverscanSize={0}
            width={width}
          />
        </div>
      )}
    </AutoSizer>

  )
}

export default StickerCollection