import { AutoSizer, Collection } from "react-virtualized"

const GUTTER_SIZE = 3
const CELL_WIDTH = 80
const COLUMN = 5
const COLUMN_HEIGHT = 35

const EmojiCollection = ({ value, onSelect }) => {
  const _columnYMap = []

  return (
    <AutoSizer >
      {({ width }) => (
        <div >
          <Collection
            cellCount={_.size(value)}
            cellSizeAndPositionGetter={({ index }) => {
              const columnCount = COLUMN
              const columnPosition = index % (columnCount || 1)

              // Poor man's Masonry layout; columns won't all line up equally with the bottom.
              const height = COLUMN_HEIGHT
              const width = CELL_WIDTH;
              const x = columnPosition * (GUTTER_SIZE + width)
              const y = _columnYMap[columnPosition] || 0

              _columnYMap[columnPosition] = y + height + GUTTER_SIZE;

              return {
                height,
                width,
                x,
                y,
              }
            }}
            cellRenderer={({ index, key, style }) => {
              return (
                <span
                  role="button"
                  key={key}
                  style={style}
                  onClick={() => onSelect?.(_.get(value, [index, 'char']))}
                  className="emoji-item"  >
                  {_.get(value, [index, 'char'])}
                </span>
              )
            }}
            height={410}
            horizontalOverscanSize={0}
            verticalOverscanSize={0}
            width={width}
          />
        </div>
      )}
    </AutoSizer>

  )
}

export default EmojiCollection