import emojiService from "@services/emojiService"
import classname from "classname"
import _ from "lodash"
import { useState } from "react"
import { useAsync } from "react-use"
import EmojiCollection from "./EmojiCollection"
import stickerService from "@services/stickerService"
import StickerCollection from "./StickerCollection"


const Loading = () => (
  <div className="d-flex align-items-center justify-content-center flex-fill">
    <div className="spinner-border text-light" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
)

const ChatMessageEmoji = ({ open, onClose, onSelect }) => {
  const [tab, setTab] = useState('emoji')

  const { value, loading } = useAsync(async () => {
    if (open) {
      if (tab === 'emoji')
        return await emojiService.getAll()

      return stickerService.getAll()
    }
  }, [open, tab])

  const handleOnSelect = (type, target) => {
    onSelect?.(type, target)
  }

  return (
    <>
      <div className={classname("emoji-wrapper", { "open": open })}>
        <div className="emoji-window" >
          <div className="emoji-type">
            <a href="#" className={classname({ "active": tab === 'emoji' })} onClick={() => setTab('emoji')}>Emoji</a>
            <a href="#" className={classname({ "active": tab === 'sticker' })} onClick={() => setTab('sticker')}>Sticker</a>
          </div>
          <div className="emoji-content d-flex container p-0">
            <div id="emoji" className={classname("tab flex-fill p-0", { "active": tab === 'emoji' })}>
              {tab === 'emoji' && loading && <Loading />}
              {tab === 'emoji' && !loading && <EmojiCollection value={value} onSelect={e => handleOnSelect('emoji', e)} />}
            </div>
            <div id="sticker" className={classname("tab flex-fill", { "active": tab === 'sticker' })}>
              {tab === 'sticker' && loading && <Loading />}
              {tab === 'sticker' && !loading && <StickerCollection value={value} onSelect={e => handleOnSelect('sticker', e)} />}
            </div>
          </div>
        </div>
      </div>

      {open && <div className="emoji-overlay" onClick={() => onClose?.()}></div>}
    </>
  )
}

export default ChatMessageEmoji