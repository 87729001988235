import { useSelector } from "react-redux"
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, WindowScroller } from "react-virtualized"
import ChatMessageItem from "./ChatMessageItem"
import { useEffect, useRef, useState } from "react"
import { v4 } from 'uuid'


const ChatMessageContent = () => {
  const _windowScroller = useRef()
  const messageContainerRef = useRef()
  const { chatMessages } = useSelector(state => state.chatMessage)
  const [scrollToIndex, setScrollToIndex] = useState(-1)
  const [renderKey, setRenderKey] = useState(v4())

  const _cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 40,
  })

  const rowRender = ({ index, key, parent, style }) => {
    return (
      <CellMeasurer
        cache={_cache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}>
        {({ measure, registerChild }) => (
          <ChatMessageItem onLoad={measure} registerChild={registerChild} chatItem={chatMessages[index]} key={key} style={style} />
        )}
      </CellMeasurer>
    )
  }

  useEffect(() => {
    window.addEventListener('resize', onresize)
  }, [])

  const onresize = _.debounce(() => {
    setRenderKey(v4())
  }, 500)

  useEffect(() => {
    if (_.some(chatMessages))
      setScrollToIndex(_.size(chatMessages) - 1)

  }, [chatMessages])

  return (
    <div className="messages" >
      <div className="messages-content" ref={messageContainerRef}>
        <WindowScroller ref={_windowScroller} scrollElement={messageContainerRef.current}>
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
            <AutoSizer key={renderKey}>
              {({ width, height }) => (
                <div ref={registerChild}>
                  <List
                    onScroll={onChildScroll}
                    deferredMeasurementCache={_cache}
                    overscanRowCount={0}
                    rowCount={_.size(chatMessages)}
                    rowRenderer={rowRender}
                    width={width}
                    height={height}
                    rowHeight={_cache.rowHeight}
                    scrollToIndex={scrollToIndex}
                  />
                </div>
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      </div>
    </div>
  )
}

export default ChatMessageContent