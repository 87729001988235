export const actionTypes = {
  SET_CHAT_MESSAGES: '@@SET_CHAT_MESSAGES',
  PUSH_CHAT_MESSAGE: '@@APPEND_CHAT_MESSAGE'
}

export const setChatMessages = chatMessages => ({
  type: actionTypes.SET_CHAT_MESSAGES,
  chatMessages
})

export const pushChatMessage = chatMessage => ({
  type: actionTypes.PUSH_CHAT_MESSAGE,
  chatMessage
})