import { useSelector } from "react-redux"

const ChatTitle = () => {
  const { hideChatTitle } = useSelector(({ globalSettings }) => globalSettings)

  if (hideChatTitle)
    return null

  return (
    <div className="chat-title d-flex justify-content-between align-items-center p-3">
      <div data-person="person-avatar" className="d-flex justify-content-center align-items-center rounded-circle me-2">
        <img src="/public/img/avatar-svgrepo-com.svg" />
      </div>
      <h1 id="personal" className="flex-fill"></h1>
      <span className="online-bullet"><span className="chat-message-counter" id="client-count">3</span></span>
    </div>

  )
}

export default ChatTitle