import { combineReducers } from 'redux';
import globalSettings from './globalSettings'
import chat from './chat'
import chatMessage from './chatMessage'

export default () =>
  combineReducers({
    globalSettings,
    chat,
    chatMessage
  })