import { yupResolver } from "@hookform/resolvers/yup"
import authService from "@services/authService"
import { initStaticGuest } from "@stores/actions/chat"
import className from 'classname'
import _ from "lodash"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2"
import * as yup from "yup"
import ChatMessageEmoji from "./ChatMessageEmoji"
import { useState } from "react"


const schema = yup
  .object({
    message: yup.string().nullable().required()
  })
  .required()

const ChatMessageInput = () => {
  const dispatch = useDispatch()
  const [openEmoji, setOpenEmoji] = useState(false)
  const { currentUser, roomId } = useSelector(state => state.chat)
  const { register, handleSubmit, reset, getValues } = useForm({ resolver: yupResolver(schema) })

  const onSubmit = input => {
    window.socket.emit('pub.send', {
      ...currentUser,
      roomId,
      message: _.trim(input.message)
    })

    reset({ message: null })
  }

  const logout = () => {
    return Swal.fire({
      title: "Bạn muốn thoát",
      showDenyButton: true,
      confirmButtonText: "Đồng ý",
      denyButtonText: `Đóng`
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(initStaticGuest())
        authService.saveAccessToken('')
      }
    })
  }

  const handleSelectEmoji = (type, item) => {
    if (!authService.hasEndUserUse(currentUser))
      return

    if (type === 'emoji') {
      reset({
        message: _.trim(`${_.trim(getValues('message'))} ${item}`)
      })
    }

    if (type === 'sticker') {
      window.socket.emit('pub.send', {
        ...currentUser,
        roomId,
        message: '',
        sticker: item
      })
    }
  }

  const onOpenEmoji = () => {
    if (!authService.hasEndUserUse(currentUser))
      return

    setOpenEmoji(!openEmoji)
  }

  return (
    <div className="chat-options d-flex flex-fill">
      <form className="position-relative d-flex flex-fill txt" onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          {...register("message", { required: true })}
          className={className("message-input form-control form-control-lg rounded d-flex align-items-center ps-5", { "pe-5": authService.hasEndUserUse(currentUser) })}
          placeholder="Gõ nội dung..."
          readOnly={!authService.hasEndUserUse(currentUser)}
        />
        <span className="position-absolute emoji" onClick={onOpenEmoji}><i className="fa-solid fa-face-grin"></i></span>
        {authService.hasEndUserUse(currentUser) && <span className="position-absolute authorized" onClick={logout}><i class="fa-solid fa-user-lock"></i></span>}
        <button type="submit" className="message-submit btn btn-primary ms-2 px-3">
          <span><i className="fa-solid fa-paper-plane"></i></span>
        </button>
      </form>

      <ChatMessageEmoji
        onSelect={handleSelectEmoji}
        open={authService.hasEndUserUse(currentUser) && openEmoji}
        onClose={() => setOpenEmoji(false)}
      />
    </div>
  )
}

export default ChatMessageInput