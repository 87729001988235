import ChatMessageContent from "@components/ChatMessageContent"
import ChatMessageInput from "@components/ChatMessageInput"
import ChatTitle from "@components/ChatTitle"
import ChatUserLogin from "@components/ChatUserLogin"

const Home = () => {
  return (
    <div className="chat">
      <ChatTitle />
      <ChatMessageContent />
      <div className="message-box d-flex flex-column p-3">
        <ChatUserLogin />
        <ChatMessageInput />
      </div>

      <div className="loading">
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <span role="status" className="ms-2">Đang khởi tạo</span>
        </div>
      </div>
    </div>
  )
}

export default Home