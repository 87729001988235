import userTypes from "@services/userService/userTypes"
import { actionTypes } from "@stores/actions/chat"
import { v4 } from 'uuid'

const defaultState = {
  roomId: null,
  socketId: null,
  currentUser: {
    name: '',
    userName: '',
    userId: '',
    level: 1,
    userType: 'guest'
  }
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_ROOM_ID:
      return {
        ...state,
        roomId: action.roomId
      }
    case actionTypes.SET_SOCKET_KET_ID:
      return {
        ...state,
        socketId: action.socketId
      }
    case actionTypes.INIT_CURRENT_USER_AS_GUEST:
      return {
        ...state,
        currentUser: {
          ...action.currentUser,
          name: 'Guest',
          userId: v4(),
          userType: userTypes.guest
        }
      }
    case actionTypes.LOG_OUT:
      return {
        ...state,
        currentUser: {
          name: '',
          userName: '',
          userId: v4(),
          level: 1,
          userType: userTypes.guest
        }
      }
    case actionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: {
          ...action.currentUser
        }
      }
    case actionTypes.SET_GUEST_USER_NAME:
      const { currentUser } = state
      currentUser.userName = action.userName
      currentUser.name = action.userName

      return {
        ...state,
        currentUser: {
          ...currentUser,
          userId: v4(),
          userType: userTypes.guest
        }
      }
    default:
      return state;
  }
}
