import userTypes from '@services/userService/userTypes'
import { v4 } from 'uuid'

export const actionTypes = {
  SET_ROOM_ID: '@@SET_ROOM_ID',
  SET_SOCKET_KET_ID: '@@SET_SOCKET_KET_ID',
  INIT_CURRENT_USER_AS_GUEST: '@@INIT_CURRENT_USER_AS_GUEST',
  SET_GUEST_USER_NAME: '@@SET_GUEST_USER_NAME',
  LOG_OUT: '@@LOG_OUT',
  SET_CURRENT_USER: '@@SET_CURRENT_USER'
}

export const setRoomId = roomId => ({
  type: actionTypes.SET_ROOM_ID,
  roomId
})

export const setSocketId = socketId => ({
  type: actionTypes.SET_SOCKET_KET_ID,
  socketId
})

export const setGuestUserName = userName => ({
  type: actionTypes.SET_GUEST_USER_NAME,
  userName
})

export const setCurrentUser = currentUser => ({
  type: actionTypes.SET_CURRENT_USER,
  currentUser
})

export const logoutCurrentUser = () => ({
  type: actionTypes.LOG_OUT
})

export const initStaticGuest = (level = 1) => ({
  type: actionTypes.INIT_CURRENT_USER_AS_GUEST,
  currentUser: {
    userName: 'Guest',
    userId: v4(),
    level,
    userType: userTypes.guest
  }
})