const getSettings = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        socketUrl: process.env.REACT_APP_SOCKET_URL,
        chatbotServerUrl: process.env.REACT_APP_BASE_URL,
        apiGatewayUrl: process.env.REACT_APP_BASE_URL,
        fileServerUrl: process.env.REACT_APP_FILE_SERVER_URL,
        hideChatTitle: true
      })

    }, 1000)
  })
}


export default {
  getSettings
}