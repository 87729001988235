import settingService from '@services/settingService';
import { persistor, store } from '@stores';
import { initStaticGuest, setRoomId, setSocketId } from '@stores/actions/chat';
import { pushChatMessage, setChatMessages } from '@stores/actions/chatMesage';
import { setGlobalSettings } from '@stores/actions/globalSettings';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { io } from "socket.io-client";
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'))

settingService.getSettings()
  .then(settings => {
    const { chat } = store.getState()

    if (!chat.currentUser?.userId)
      store.dispatch(initStaticGuest())

    store.dispatch(setGlobalSettings(settings))

    return {
      settings,
      roomId: '7868948c-483d-46ea-aba8-d1c3f5f04390'
    }
  })
  .then(({ settings, roomId }) => {
    const { chat } = store.getState()
    const { currentUser } = chat

    window.socket = io(settings.socketUrl)

    window.socket.on('connect', () => {
      store.dispatch(setSocketId(socket.id))
      store.dispatch(setRoomId(roomId))

      window.socket.emit('pub.join', {
        userName: currentUser.userName,
        roomId: roomId,
        userId: currentUser.userId,
        level: currentUser.level,
        guest: currentUser.guest
      })
    })

    window.socket.on('sub.clients', data => {

    })

    window.socket.on('sub.messages', messages => {
      store.dispatch(setChatMessages(_.sortBy(messages, 'createdAt')))
    })

    window.socket.on('sub.chat', chatMessage => {
      if (chatMessage.notifyMsg)
        return

      store.dispatch(pushChatMessage(chatMessage))
    })

    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </React.StrictMode>
    )
  })


reportWebVitals();
